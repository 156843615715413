.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

}

.register{
  font-size: 16px;
}

.loginUser{
  font-size: 16px;
}

.register::before {
  font-family: "FontAwesome"; font-weight: 300; content: "\f234"; font-size: 26px;
}

.loginUser::before {
  font-family: "FontAwesome"; font-weight: 300; content: "\f2bd"; font-size: 26px;
}

.username::before {
   font-family: "FontAwesome"; font-weight: 300; content: "\f007"; font-size: 40px; color: #b1dfbb;
 }

.password::before {
  font-family: "FontAwesome"; font-weight: 300; content: "\f023"; font-size: 40px; color: #b1dfbb;
}

.error::before {
  font-family: "FontAwesome"; font-weight: 300; content: "\f06a"; font-size: 15px; color: #C82829;padding-right: 10px;
}

.email::before {
  font-family: "FontAwesome"; font-weight: 300; content: "\f1fa"; font-size: 40px; color: #b1dfbb; padding-right: 10px;
}

.folder::before {
  font-family: "FontAwesome"; font-weight: 300; content: "\f07c"; font-size: 26px;
}

.trash::before {
  font-family: "FontAwesome"; font-weight: 300; content: "\f1f8"; font-size: 15px;
}

.error{
  font-size: 15px; color: #C82829;
}

.label {
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  text-align: right;

  position: absolute;
  left: -105px;
  width: 80px;
}

.labelMail {
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  text-align: right;

  position: absolute;
  left: -95px;
  width: 80px;
}

